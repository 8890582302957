export const CustomPiecesConstants = Object.freeze({
    ZOOM_FACTOR: 2.5,
    TILE_WIDTH_OFFSET: 0.7,
    PIECE_DICTIONARY: [
        { piece: 'wP' },
        { piece: 'wN' },
        { piece: 'wB' },
        { piece: 'wR' },
        { piece: 'wQ' },
        { piece: 'wK' },
        { piece: 'bP' },
        { piece: 'bN' },
        { piece: 'bB' },
        { piece: 'bR' },
        { piece: 'bQ' },
        { piece: 'bK' },
    ],
});

export const CustomBoardConstants = Object.freeze({
    AI_RESPONSE_DELAY: 1000,
    ANIMATION_DURATION: 200,
    AI_PROMOTION_TYPE: 'q',
    KING_SQUARE_INCHECK_STYLES: {
        background:
            'radial-gradient(circle, rgba(255, 0, 0, 0.8) 20%, rgba(255, 0, 0, 0.4) 50%, transparent 80%)',
        animation: 'king-flash 1s infinite',
    },
    CAPTURE_MOVE_SQUARE_STYLES: 'rgba(125, 0, 0, 0.5) 60%, transparent 70%',
    AVAIL_MOVE_SQUARE_STYLES: 'rgba(0, 0, 0, 0.5) 20%, transparent 20%',
});

export const MultiplayerConstants = Object.freeze({
    WS_URL: 'https://3kex2sao88.execute-api.us-west-2.amazonaws.com/nonprod/',
    RETRY_DELAY: 3000,
    MAX_RECONNECT_RETRIES: 5,
    PING_INTERVAL: 30000,
});

export const WaitingRoomConstants = Object.freeze({
    COPY_DELAY: 2000,
    QR_CODE_CONFIG: {
        width: 200,
        height: 200,
        margin: 2,
        color: {
            dark: '#230C01',
            light: '#d9b996',
        },
    },
});
