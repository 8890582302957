/**
 * App.js
 * Main component for the chess app.
 *
 * @author Braden Zingler
 * Last modified 10/09/2024
 */
import './App.css';
import React, { useState, useEffect } from 'react';
import { useGame } from './components/game/GameContext';
import { Chess } from 'chess.js';
import GameOver from './components/interfaces/GameOver/GameOver';
import Menu from './components/interfaces/Menu/Menu';
import Candle from './components/visual/Candle';
import Cards from './components/visual/Cards';
import CoffeeMug from './components/visual/CoffeeMug';
import CustomChessboard from './components/game/CustomChessboard/CustomChessboard';
import Promotion from './components/interfaces/Promotion';
import { initGameCenter } from './utils/GameCenter';
import StartMenu from './components/interfaces/StartMenu';
import MultiplayerMenu from './components/multiplayer/MultiplayerMenu';
import { removeGameIDFromURL } from './utils/utils';
import { MultiplayerConstants } from './utils/constants';
import {
    handleClose,
    handleJoin,
    handleError,
    handleMessage,
} from './utils/multiplayer/handlers';
import LoadingScreen from './components/interfaces/LoadingScreen';

/**
 * Handles the main logic for the chess app.
 * Sets up the game state, restarts the game, and renders the game board.
 *
 * @component App
 * @returns {JSX.Element} The main component for the chess app.
 */
export default function App() {
    const {
        playAI,
        setPlayAI,
        gameID,
        setGameID,
        showGame,
        setShowGame,
        ws,
        setWs,
        gameState,
        setGameState,
        joinGame,
        setJoinGame,
        game,
        setGame,
        setPlayerColor,
        playerColor,
        setFullGame,
        fullGame,
        setForfeit,
        forfeit
    } = useGame();

    const [clickedPiece, setClickedPiece] = useState(null); // The piece that was clicked.
    const [availableMoves, setAvailableMoves] = useState([]); // The available moves for the clicked piece.
    const [promotionType, setPromotionType] = useState(null); // The piece to promote to.
    const [isPromotion, setIsPromotion] = useState(false); // Flag to show/hide promotion dialog.
    const [aiDifficulty, setAiDifficulty] = useState(1); // The difficulty of the AI opponent, default medium.
    const [lastMove, setLastMove] = useState(null); // The last move made in the game.

    useEffect(() => {
        const gameID = window.location.hash.replace('#', '');
        // If there is a game ID in the URL, we should join the multiplayer game.
        if (gameID) {
            setPlayAI(false);
            setGameID(gameID);
            setJoinGame(true);
        }
        // eslint-disable-next-line
    }, [window.location.hash]);

    /**
     * Initalizes the game center and any local storage values.
     * Checks for a saved Game ID and AI difficulty.
     */
    useEffect(() => {
        initGameCenter();
        let savedDifficulty = localStorage.getItem('aiDifficulty');
        if (savedDifficulty) {
            setAiDifficulty(parseInt(savedDifficulty));
        }
        // eslint-disable-next-line
    }, []);

    /**
     * Attempt to join a multiplayer game if joinGame is true and no WebSocket connection exists.
     * This handles joining a game, not hosting a game.
     */
    useEffect(() => {
        if (joinGame && gameID && !ws) {
            removeGameIDFromURL();
            setPlayAI(false);
            setLastMove(null);

            const socket = new WebSocket(MultiplayerConstants.WS_URL);
            setWs(socket);
            socket.onopen = () => {
                handleJoin(gameID, socket);
                setInterval(() => {
                    socket.send(JSON.stringify({action: 'ping'}));
                }, MultiplayerConstants.PING_INTERVAL);
            };
            socket.onmessage = (event) => {
                handleMessage(
                    event,
                    setShowGame,
                    setGameState,
                    setGame,
                    setPlayerColor,
                    setGameID,
                    setFullGame,
                    setForfeit
                );
            };
            socket.onclose = (event) => {
                handleClose(setWs, setJoinGame, playAI, showGame);
            };
            socket.onerror = (error) => {
                handleError(error, socket);
            };
        }
        // eslint-disable-next-line
    }, [joinGame, gameID, ws, setWs]);

    /**
     * Update the game state to localStorage whenever the game position changes.
     * This saves the game state only for AI games.
     */
    useEffect(() => {
        if (game && playAI) {
            localStorage.setItem('ai-chessGameState', game.fen());
            localStorage.setItem('aiDifficulty', aiDifficulty);
        }
    }, [game, gameState, aiDifficulty, playAI]);

    /**
     * Restarts the game by creating a new Chess instance and resetting the game state.
     *
     * Useful restart states:
     * Easy to win: "r3kbnr/PPP1pppp/4ppnb/5ppq/8/8/4PPPP/RNBQKBNR w KQkq - 0 1"
     *
     * Place the restart state in the Chess constructor to start the game in that state.
     */
    function restartGame() {
        const newGame = new Chess();
        setGame(newGame);
        setGameState(newGame.fen());
        setClickedPiece(null);
        setAvailableMoves([]);
        setLastMove(null);
        localStorage.removeItem('ai-chessGameState');
        document.getElementsByClassName("chessboard-wrapper")[0].style.animation = "restart-board 0.5s";
        setTimeout(() => {
            document.getElementsByClassName("chessboard-wrapper")[0].style.animation = "";
        }, 500);
    }

    // Only show the game board if the user has selected to play the game.
    if (!showGame) {
        // Show the loading screen if the user is joining a multiplayer game.
        if (joinGame) {
            return (
                <LoadingScreen
                    isLoading={!fullGame}
                    setJoinGame={setJoinGame}
                    message={fullGame ? 'Game is full.' : 'Joining game...'}
                />
            );
        }

        // Render the start menu otherwise.
        return (
            <div className="chess-app" data-testid="chess-app">
                <StartMenu />
                <MultiplayerMenu setLastMove={setLastMove}/>
            </div>
        );
    } else {
        // Render the game board.
        return (
            <div className="chess-app" data-testid="chess-app">
                {/* TODO: This can be its own component eventually. */}
                {!playAI && (
                    <div
                        id="current-turn"
                        className={`${game.turn() === playerColor ? 'flashing-turn' : ''}`}>
                        {game.turn() === playerColor
                            ? `It's your turn!`
                            : `Opponents move...`}
                    </div>
                )}
                <Candle />
                <Cards />
                <Menu
                    restartGame={restartGame}
                    aiDifficulty={aiDifficulty}
                    setAiDifficulty={setAiDifficulty}
                />
                <CoffeeMug />
                <GameOver
                    isGameOver={game.isGameOver() || forfeit.forfeit}
                    winner={game.turn() === 'w' ? 'Black' : 'White'}
                    result={game.isCheckmate() ? 'Checkmate!' : 'Stalemate!'}
                    onRestart={() => restartGame()}
                    setShowGame={setShowGame}
                    setLastMove={setLastMove}
                />
                <Promotion
                    isOpen={isPromotion}
                    setPromotionType={setPromotionType}
                    onClose={() => setIsPromotion(false)}
                />
                <CustomChessboard
                    game={game}
                    lastMove={lastMove}
                    setLastMove={setLastMove}
                    restartGame={restartGame}
                    clickedPiece={clickedPiece}
                    setClickedPiece={setClickedPiece}
                    availableMoves={availableMoves}
                    setAvailableMoves={setAvailableMoves}
                    isPromotion={isPromotion}
                    setIsPromotion={setIsPromotion}
                    promotionType={promotionType}
                    setPromotionType={setPromotionType}
                    aiDifficulty={aiDifficulty}
                />
            </div>
        );
    }
}
