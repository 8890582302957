/**
 * StartMenu/index.js
 * The start menu for the chess app.
 *
 * @component StartMenu
 * @author Braden Zingler
 * Last modified 10/07/2024
 */
import React from 'react';
import { useGame } from '../../game/GameContext';
import { quitGame } from '../../../utils/GameCenter';
import { Chess } from 'chess.js';
import './index.css';
import { isAuthenticated, redirectToLoginPage } from '../../../utils/auth';

export default function StartMenu() {
    const {
        showGame,
        setShowGame,
        setPlayAI,
        setPlayerColor,
        setGame,
        setGameState,
        showMultiplayerMenu,
        setShowMultiplayerMenu,
    } = useGame();

    /**
     * Start a game against the computer.
     * @param {Event} e the click event
     */
    function handlePlayComputerClick(e) {
        setPlayAI(true);
        setPlayerColor('w');
        let savedGame = localStorage.getItem('ai-chessGameState');
        let chessInstance = savedGame ? new Chess(savedGame) : new Chess();
        setGame(chessInstance);
        setGameState(chessInstance.fen());
        setShowGame(true);
    }

    /**
     * Handles the multiplayer button click event.
     * Shows the multiplayer menu.
     * @param {Event} e the click event from the button.
     */
    async function handleMultiplayerClick(e) {
        if (await isAuthenticated()) {
            setShowMultiplayerMenu(true);
        } else {
            redirectToLoginPage(window.location.href);
        }
    }

    /**
     * Handles the quit button click event.
     * Posts a message to the parent window to exit the game.
     * @param {Event} e the click event
     */
    function handleQuitClick(e) {
        quitGame();
    }

    // Do not show the start menu if the game is already started.
    if (showGame || showMultiplayerMenu) {
        return null;
    }

    return (
        <div className="start-menu" data-testid="start-menu">
            <h2>Lakeside Chess</h2>
            <button
                className="chess-button"
                onClick={(e) => handlePlayComputerClick(e)}
                data-testid="start-button">
                Play Computer
            </button>
            <button
                onClick={async (e) => await handleMultiplayerClick(e)}
                className="chess-button">
                Multiplayer
            </button>
            <button
                onClick={(e) => handleQuitClick(e)}
                className="chess-button">
                Quit
            </button>
        </div>
    );
}
