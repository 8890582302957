import './Menu.css';
import { useGame } from '../../game/GameContext';
import { FaHome } from 'react-icons/fa';
import { IoMdRefresh } from 'react-icons/io';
import { MdOutlineCancel } from "react-icons/md";

/**
 * Creates the in-game menu on the top right of the screen.
 * @param {Object} props the properties for the Menu component.
 * @returns the Menu component
 */
export default function Menu({ restartGame, aiDifficulty, setAiDifficulty }) {
    const { ws, playAI, setShowGame, setJoinGame, gameID } = useGame();

    /**
     * Handles the home button click by closing the WebSocket connection and
     * setting the game state to the home screen.
     */
    function handleHome() {
        setShowGame(false);
        setJoinGame(false);
    }

    function forfeitGame() {
        ws.send(
            JSON.stringify({
                action: 'forfeit',
                gameID: gameID,
                playerID: localStorage.getItem('userID'),
            })
        );
    }

    return (
        <div className="menu">
            {/* AI difficulty selection */}
            {playAI && (
                <div id="difficulty">
                    <select
                        data-testid="difficulty"
                        name="select"
                        value={aiDifficulty}
                        onChange={(e) => {
                            setAiDifficulty(e.target.value);
                        }}>
                        <option value="0">Easy</option>
                        <option value="1">Medium</option>
                        <option value="2">Hard</option>
                        <option value="3">Very Hard</option>
                    </select>
                </div>
            )}

            {/* Restart, cancel, and home buttons */}
            {playAI && (
                <IoMdRefresh
                    data-testid="restart"
                    className="menu-icon restart"
                    onClick={restartGame}
                />
            )}
            {!playAI && (
                <MdOutlineCancel
                    data-testid="cancel"
                    className="menu-icon"
                    onClick={forfeitGame}
                />
            )}
            <FaHome
                data-testid="home"
                className="menu-icon"
                onClick={handleHome}
            />
        </div>
    );
}
