import { useGame } from '../../game/GameContext';
import './GameOver.css';

/**
 * GameOver component
 * Handles the game over modal, allows the user to restart the game or go back to the home screen
 * @returns the game over modal
 */
export default function GameOver({
    isGameOver,
    result,
    onRestart,
    setShowGame,
    setLastMove,
    winner
}) {
    const { gameID, ws, playAI, game, playerColor, forfeit, setForfeit, setGameID, setJoinGame } = useGame();
    if (!isGameOver) {
        return null;
    }

    /**
     * Handles the home button from the GameOver modal.
     * Sends a message to the backend to end the game and deletes the game in the database.
     */
    function handleHome() {
        setShowGame(false);
        setJoinGame(false);
        setLastMove(null);
        setGameID(null);
        setForfeit({ forfeit: false, winner: '' });
        if (!playAI) {
            ws.send(
                JSON.stringify({
                    action: 'end',
                    gameID: gameID,
                })
            );
        }
    }

    /**
     * Handles the share button, opens the user's email client with the game state
     * as the body of the email.
     * TODO this will be much more comprehensive (html) in the future. Email is simple for now.
     */
    function onShare() {
        const subject = `Check out this game I just ${playerColor === winner ? 'won' : 'lost'} on Lakeside Chess!`;
        let ascii = game.ascii();
        let urlEncoded = encodeURI(ascii);
        const mailTo = `mailto:?subject=${subject}&body=${urlEncoded}`;
        window.open(mailTo);
    }

    const forfeitWinner = forfeit.winner === 'w' ? 'White' : 'Black';

    return (
        <div className="overlay" data-testid="game-over">
            <div className="game-over-modal">
                <h1>{forfeit.forfeit ? "Forfeit!" : result}</h1>
                <h2>{forfeit.forfeit ? forfeitWinner : winner} wins!</h2>
                <div id="buttons">
                    <button onClick={onRestart} className="chess-button">
                        Restart Game
                    </button>
                    <button onClick={onShare} className="chess-button">
                        Share
                    </button>
                    <button onClick={handleHome} className="chess-button">
                        Home
                    </button>
                </div>
            </div>
        </div>
    );
}
