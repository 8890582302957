/**
 * CustomChessboard.js
 * Handles the chessboard logic for the chess app.
 *
 * @author Braden Zingler
 * Last modified 10/08/2024
 */
import { CustomBoardConstants } from '../../../utils/constants';
import {
    calcBoardPadding,
    calcBoardWidth,
    findKingFromFenString,
    calculateIsPromotion,
    useMountTransition,
} from '../../../utils/utils';
import { useCustomPieces } from '../CustomPieces';
import { useEffect, useState, useRef } from 'react';
import usePlayAudio from '../../MoveAudio';
import './CustomChessboard.css';
import { Chessboard, ChessboardDnDProvider } from 'react-chessboard';
import { aiMove } from 'js-chess-engine';
import { useGame } from '../GameContext';
import { sendMultiplayerGameUpdate } from '../../../utils/multiplayer/handlers';

/**
 * The CustomChessboard component is a wrapper around the react-chessboard component.
 * It provides additional functionality such as
 * highlighting available moves, drag-and-drop, and promotion.
 *
 * @param {obj} obj the props object
 * @returns the CustomChessboard component
 */
export default function CustomChessboard({
    game,
    clickedPiece,
    setClickedPiece,
    lastMove,
    setLastMove,
    availableMoves,
    setAvailableMoves,
    promotionType,
    setPromotionType,
    isPromotion,
    setIsPromotion,
    aiDifficulty,
}) {
    const [hoveredSquare, setHoveredSquare] = useState(null); // The square that is being hovered over
    const [pendingMove, setPendingMove] = useState(null); // Stores the pending move details
    const playerTurn = useRef(true); // Track if players turn
    const playAudio = usePlayAudio();

    const { playAI, gameState, setGameState, ws, gameID, playerColor, showGame } = useGame();
    const hasTransitionedIn = useMountTransition(showGame, 1000);

    /**
     * Update playerTurn ref when gameState changes and make AI move appropriately.
     * This only runs when the AI is playing.
     */
    useEffect(() => {
        if (game.turn() === 'b' && playerTurn.current && !game.isGameOver()) {
            playerTurn.current = game.turn() === 'w'; // Mark it's opponents turn.
            if (playAI) {
                makeAImove();
            }
        } else if (game.turn() === 'w') {
            playerTurn.current = true; // Mark it's player's turn
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameState]);

    /**
     * Handle promotion and game movement when promotionType changes.
     * Pending moves are stored in state to complete the move after the promotion type is selected.
     * We need to wait for the promotion type to be selected before completing the move.
     */
    useEffect(() => {
        if (promotionType && pendingMove) {
            handleMove(pendingMove.from, pendingMove.to, promotionType);
            setPromotionType(null);
            setIsPromotion(false);
            setPendingMove(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        promotionType,
        pendingMove,
        setPromotionType,
        setIsPromotion,
        setPendingMove,
        setIsPromotion,
        setPromotionType,
        handleMove
    ]);

    /**
     * Handles the click event on a square.
     * Highlights available moves for the clicked piece.
     * @param {*} square the square that was clicked.
     */
    function onSquareClick(square) {
        if (!square) {
            throw new Error('Square is not defined.');
        }
        setClickedPiece(null);
        setAvailableMoves([]);

        // Do not allow moves while promotion dialog is open, or not the player's turn
        if (
            isPromotion ||
            clickedPiece === square ||
            game.turn() !== playerColor
        ) {
            return;
        }

        // If clicking on an available move square, make the move
        if (clickedPiece && availableMoves.includes(square)) {
            const isPromotionMove = handlePromotion(clickedPiece, square);

            // If the move is not a promotion, normal move
            if (!isPromotionMove) {
                handleMove(clickedPiece, square);
            }
        } else if (game.get(square) && game.get(square).color === game.turn()) {
            // If a new piece is clicked, highlight its available moves
            if (game.get(square).color !== playerColor) {
                return; // Not the player's piece
            }
            const moves = game.moves({ square, verbose: true });
            const available = moves.map((move) => move.to);
            setClickedPiece(square);
            setAvailableMoves(available);
        }
    }

    /**
     * Handles the drop event (drag-and-drop).
     * Shows the available moves for the piece being dragged.
     * @param {String} sourceSquare the source square of the piece.
     * @param {String} targetSquare the target square of the piece.
     * @param {String} piece the piece being moved (not used).
     */
    function onDrop(sourceSquare, targetSquare, piece) {
        if (
            sourceSquare === targetSquare ||
            isPromotion ||
            game.turn() !== playerColor
        ) {
            // Did not move or promotion dialog is open.
            return false;
        }
        const isPromotionMove = handlePromotion(sourceSquare, targetSquare);
        if (!isPromotionMove) {
            handleMove(sourceSquare, targetSquare);
        }
        return true;
    }

    /**
     * Moves a piece on the board.
     * Handles the move and updates the game position.
     *
     * @param {String} sourceSquare the source square of the piece.
     * @param {String} targetSquare the target square of the piece.
     * @param {String} promotion the promotion type (defaulted to undefined).
     * @returns {boolean} true if the move was successful, false otherwise.
     */
    function handleMove(sourceSquare, targetSquare, promotion = undefined) {
        try {
            const move = game.move({
                from: sourceSquare,
                to: targetSquare,
                promotion: promotion,
            });
            if (move !== null) {
                playAudio();
                setGameState(game.fen());
                // Send the game state to the opponent, to update both boards.
                if (!playAI) {
                    sendMultiplayerGameUpdate(ws, gameID, game.fen());
                }
                setClickedPiece(null);
                setAvailableMoves([]);
                setLastMove({ from: sourceSquare, to: targetSquare });
                return true;
            }
        } catch (error) {
            console.log(error); // invalid move.
            return false;
        }
    }

    /**
     * Makes a move for the AI.
     * If the AI has a promotion, it defaults to a queen promotion since it can't make a choice.
     */
    async function makeAImove() {
        // Delay AI move for better UX
        await new Promise((r) =>
            setTimeout(r, CustomBoardConstants.AI_RESPONSE_DELAY)
        );
        const move = aiMove(game.fen(), aiDifficulty);
        const [from, to] = Object.entries(move)[0];
        handleMove(
            from.toLowerCase(),
            to.toLowerCase(),
            CustomBoardConstants.AI_PROMOTION_TYPE
        );
    }

    /**
     * Handles the drag event.
     * Shows the available moves for the piece being dragged.
     * @param {String} piece the piece being dragged.
     * @param {String} sourceSquare the source square of the piece.
     */
    function onDragBegin(piece, sourceSquare) {
        const moves = game.moves({ square: sourceSquare, verbose: true });
        const available = moves.map((move) => move.to);
        setClickedPiece(sourceSquare);
        setAvailableMoves(available);
    }

    /**
     * Handles the promotion of a pawn.
     * Opens the promotion dialog if the move is a promotion.
     * Changes the pending move to the promotion type to complete the move.
     *
     * @param {String} sourceSquare the source square of the piece.
     * @param {String} targetSquare the target square of the piece.
     */
    function handlePromotion(sourceSquare, targetSquare) {
        if (!availableMoves.includes(targetSquare)) return false; // Not a valid move
        const piece = game.get(sourceSquare);
        if (calculateIsPromotion(sourceSquare, targetSquare, piece)) {
            setIsPromotion(true); // Open promotion dialog
            setPendingMove({ from: sourceSquare, to: targetSquare, piece }); // Store the pending move
            return true;
        }
        return false;
    }

    /**
     * Highlights squares for available moves.
     * If the move is a capture, the square is highlighted in red.
     * If the game is in check, the king square is highlighted in red.
     * @returns the styles for the highlighted squares.
     */
    function highlightSquares() {
        const highlightStyles = {};
        // Highlight the king square with flashing red if in check
        if (game.inCheck()) {
            const kingSquare = findKingFromFenString(game.fen(), game.turn());
            highlightStyles[kingSquare] =
                CustomBoardConstants.KING_SQUARE_INCHECK_STYLES;
        }
        // Highlight clicked piece square
        if (clickedPiece) {
            highlightStyles[clickedPiece] = {
                background: `rgba(255, 255, 0, 0.5)`,
            };
        }
        // Highlight last move
        if (lastMove && lastMove.from && lastMove.to) {
            highlightStyles[lastMove.from] = {
                background: 'rgba(255, 255, 0, 0.5)',
            };
            highlightStyles[lastMove.to] = {
                background: 'rgba(255, 255, 0, 0.3)',
            };
        }
        // Highlight available moves when piece is selected/dragged.
        availableMoves.forEach((move) => {
            const rgba = game.get(move)
                ? CustomBoardConstants.CAPTURE_MOVE_SQUARE_STYLES // Red for capture moves.
                : CustomBoardConstants.AVAIL_MOVE_SQUARE_STYLES;
            highlightStyles[move] = {
                background: `radial-gradient(circle, ${rgba})`,
                borderRadius: '50%',
            };
        });
        return highlightStyles;
    }

    /**
     * Checks if a piece can be dragged.
     * Color must be the same as the player's color and it must be the player's turn.
     * @param {*} piece the piece to check.
     * @returns true if the piece can be dragged, false otherwise.
     */
    function allowDrag({ piece }) {
        return (
            !game.isGameOver() &&
            piece.charAt(0) === playerColor &&
            game.turn() === playerColor
        );
    }

    return (
        <div className={`chessboard-wrapper ${hasTransitionedIn && "in"} $`} data-testid="custom-chessboard">
            <ChessboardDnDProvider>
                <Chessboard
                    id="chessboard"
                    data-testid="chessboard"
                    boardOrientation={`${playerColor === 'w' ? 'white' : 'black'}`}
                    boardWidth={calcBoardWidth()}
                    autoPromoteToQueen={true}
                    onMouseOutSquare={() => setHoveredSquare(null)}
                    onMouseOverSquare={(square) => setHoveredSquare(square)}
                    position={gameState}
                    onPieceDrop={onDrop}
                    isDraggablePiece={allowDrag}
                    onPieceDragBegin={onDragBegin}
                    onSquareClick={onSquareClick}
                    promotionDialogVariant="modal"
                    animationDuration={CustomBoardConstants.ANIMATION_DURATION}
                    customSquareStyles={highlightSquares()}
                    customDarkSquareStyle={{}} // Get rid of default styles
                    customLightSquareStyle={{}}
                    customBoardStyle={{
                        transform: 'perspective(1000px) rotateX(5deg)',
                        filter: 'brightness(1.2)',
                        boxShadow: '-30px -10px 8px 0px rgba(0, 0, 0, 0.5)',
                        transformOrigin: 'center',
                        borderRadius: '10px',
                        background: 'url("/images/chess-board.webp")',
                        padding: calcBoardPadding(),
                        backgroundPosition: 'center',
                        backgroundSize: '100% 100%',
                        position: 'relative',
                    }}
                    customPieces={useCustomPieces(
                        hoveredSquare,
                        clickedPiece,
                        onSquareClick
                    )}
                />
            </ChessboardDnDProvider>
        </div>
    );
}
