import './index.css';
import { useState } from 'react';

/**
 * Creates the loading screen component.
 * @param {Boolean} isLoading the loading state.
 * @param {String} message the message to display.
 * @param {Function} setJoinGame the function to set the join game state.
 * @returns the loading screen component.
 */
export default function LoadingScreen({ isLoading, message, setJoinGame }) {
    const [isOpen, setIsOpen] = useState(true);
    // If the loading screen is not open, return null.
    if (!isOpen) {
        return null;
    }

    // Close the loading screen and return to home screen.
    const onClick = () => {
        setIsOpen(false);
        setJoinGame(false);
    };

    return (
        <div className="loading-screen">
            <div id="elements">
                <h2>{message}</h2>
                {isLoading && <div className="dot-spin"></div>}
                <button onClick={onClick} className="chess-button">
                    Home
                </button>
            </div>
        </div>
    );
}
