import './index.css';

export default function Promotion({ isOpen, setPromotionType, onClose }) {
    if (!isOpen) {
        return null;
    }

    /* If the user clicks outside the promotion dialog, close it. */
    window.addEventListener('click', (e) => {
        if (e.target.className === 'overlay') {
            onClose();
        }
        return () => {
            window.removeEventListener('click', () => {
                onClose();
            });
        };
    });

    return (
        <div className="overlay" data-testid="promotion-dialog">
            <div className="promotion-modal">
                <h1>Promote Pawn</h1>
                <div className="promotion-options">
                    <button
                        className="chess-button"
                        onClick={() => {
                            setPromotionType('q');
                            onClose();
                        }}>
                        Queen
                    </button>
                    <button
                        className="chess-button"
                        onClick={() => {
                            setPromotionType('r');
                            onClose();
                        }}>
                        Rook
                    </button>
                    <button
                        className="chess-button"
                        onClick={() => {
                            setPromotionType('b');
                            onClose();
                        }}>
                        Bishop
                    </button>
                    <button
                        className="chess-button"
                        onClick={() => {
                            setPromotionType('n');
                            onClose();
                        }}>
                        Knight
                    </button>
                    <button
                        className="chess-button"
                        onClick={() => {
                            onClose();
                        }}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}
