/**
 * CustomPieces.js
 * This file contains the logic for creating custom chess pieces.
 *
 * @author Braden Zingler
 * Last modified 10/09/2024
 */
import { useCallback, useMemo } from 'react';
import { CustomPiecesConstants } from '../../../utils/constants';
import './index.css';

/**
 * Creates custom chess piece components with their own images and styles.
 * The images are pulled from the public/images directory.
 * Each piece has a name corresponding to the image file name.
 * (e.g. "wP.webp") would be the white pawn image.
 *
 * @param {String} hoveredSquare the square that the mouse is hovering over.
 * @param {String} clickedSquare the square that the mouse is clicked on.
 * @returns a dictionary of custom chess piece components.
 */
export const useCustomPieces = (
    hoveredSquare,
    clickedSquare,
    onSquareClick,
    draggedPiece
) => {
    /**
     * Calculates the bottom offset for the chess piece.
     * Centers the piece vertically within the tile.
     * @param {Number} squareWidth the width of the square.
     * @param {Boolean} isClicked whether the piece is clicked.
     * @returns the CSS setting for the bottom offset of the chess piece.
     */
    const calculateBottomOffset = useCallback(
        (squareWidth, isClicked, isDragging) => {
            if (!squareWidth) {
                console.error('Error: squareWidth is not defined.');
            }
            const tilePos =
                CustomPiecesConstants.TILE_WIDTH_OFFSET * squareWidth;
            return `${tilePos - (CustomPiecesConstants.ZOOM_FACTOR - 1) * squareWidth}px`;
        },
        []
    );

    /**
     * Calculates the left offset for the chess piece.
     * Centers the piece horizontally.
     * @param {Number} squareWidth the width of the square.
     * @returns the CSS setting for the left offset of the chess piece.
     */
    const calculateLeftOffset = useCallback((squareWidth, isDragging) => {
        if (!squareWidth) {
            console.error('Error: squareWidth is not defined.');
        }
        return `${((1 - CustomPiecesConstants.ZOOM_FACTOR * (isDragging ? 1.5 : 1)) * squareWidth) / 2}px`;
    }, []);

    /**
     * Creates the reflection effect for the chess piece.
     * @param {Number} squareWidth the width of the square.
     * @returns the CSS setting for the reflection effect.
     */
    const getReflection = useCallback((squareWidth) => {
        if (!squareWidth) {
            console.error('Error: squareWidth is not defined.');
            return;
        }
        const tilePos = CustomPiecesConstants.TILE_WIDTH_OFFSET * squareWidth;
        const reflLocation =
            tilePos - (CustomPiecesConstants.ZOOM_FACTOR - 1) * squareWidth * 2;
        if (window.innerWidth < 900) {
            return ``; // Disable reflection on mobile devices to improve performance.
        }
        return `below ${reflLocation}px linear-gradient(transparent, rgba(0, 0, 0, 0.3))`;
    }, []);

    return useMemo(() => {
        const pieceComponents = {};
        CustomPiecesConstants.PIECE_DICTIONARY.forEach(({ piece, img }) => {
            pieceComponents[piece] = ({ squareWidth, square }) => {
                let isHovered = hoveredSquare === square;
                const isClicked = clickedSquare === square;
                const isDragging = draggedPiece === square;

                // Disable hover effects on mobile devices
                if (window.innerWidth < 600) {
                    isHovered = false;
                }

                return (
                    <div
                        /* The div containing the chess piece */
                        className="piece"
                        data-testid={square}
                        style={{
                            width: squareWidth,
                            height: squareWidth,
                            animationDelay: `${Math.random() * 0.5}s`,
                        }}>
                        <img
                            /* The image of the chess piece */
                            onClick={() => onSquareClick(square)}
                            onTouchStart={() => onSquareClick(square)}
                            src={`/images/${piece}.webp`}
                            className="chess-piece-image"
                            width={
                                (isDragging ? 1.5 : 1) *
                                squareWidth *
                                CustomPiecesConstants.ZOOM_FACTOR
                            }
                            height={
                                (isDragging ? 1.5 : 1) *
                                squareWidth *
                                CustomPiecesConstants.ZOOM_FACTOR
                            }
                            alt={piece}
                            style={{
                                left: calculateLeftOffset(
                                    squareWidth,
                                    isDragging
                                ),
                                bottom: calculateBottomOffset(
                                    squareWidth,
                                    isClicked,
                                    isDragging
                                ),
                                objectFit:
                                    piece[1] === 'K' ? 'contain' : 'cover',
                                WebkitBoxReflect: getReflection(
                                    squareWidth,
                                    isDragging
                                ),
                                opacity: isHovered ? 0.6 : 1,
                            }}
                        />
                    </div>
                );
            };
        });
        return pieceComponents;
        // eslint-disable-next-line
    }, [hoveredSquare, clickedSquare]);
};
