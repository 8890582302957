/**
 * MultiplayerMenu component.
 * All host side multiplayer logic occurs here.
 *
 * @author Braden Zingler
 * Last modified 10/04/2024
 */
import './index.css';
import { useGame } from '../../game/GameContext';
import React, { useState } from 'react';
import { MultiplayerConstants } from '../../../utils/constants';
import WaitingRoom from '../WaitingRoom';
import { MdCancel } from 'react-icons/md';
import {
    handleClose,
    handleError,
    handleHost,
    handleMessage,
} from '../../../utils/multiplayer/handlers';

/**
 * The MultiplayerMenu component.
 * Creates a menu for hosting a multiplayer game.
 * Handles the logic for hosting the multiplayer game.
 * @param {Boolean} showMultiplayer Flag to show/hide the multiplayer menu.
 * @param {Function} setShowMultiplayer Function to toggle the multiplayer menu.
 * @returns {JSX.Element} The MultiplayerMenu component.
 */
export default function MultiplayerMenu({ setLastMove }) {
    const {
        setPlayAI,
        playAI,
        gameID,
        setGameID,
        setShowGame,
        showMultiplayerMenu,
        setShowMultiplayerMenu,
        setWs,
        setGameState,
        setGame,
        setPlayerColor,
        setJoinGame,
        showGame,
        setFullGame,
        setForfeit
    } = useGame();
    const [waitingForOpponent, setWaitingForOpponent] = useState(false);

    if (!showMultiplayerMenu) {
        return null; // Hide the multiplayer menu.
    }

    /**
     * Host a new multiplayer game.
     * Read the planning document for more information:
     * https://sinclairdigital.atlassian.net/wiki/spaces/DL/pages/1103265798/Chess+MVP+Multiplayer+Implementation#Hosting-Games
     */
    function hostGame() {
        setLastMove(null);
        setPlayAI(false);
        setPlayerColor('w');

        /* The game is already started. */
        if (gameID) {
            setJoinGame(true);
            setShowGame(true);
            setWs(null);
            return;
        }
        let newWS = new WebSocket(MultiplayerConstants.WS_URL);
        newWS.onopen = () => {
            setWaitingForOpponent(true);
            handleHost(newWS, setWs);
            setInterval(() => {
                newWS.send(JSON.stringify({action: 'ping'}));
            }, MultiplayerConstants.PING_INTERVAL);
        };
        newWS.onclose = () => {
            handleClose(setWs, setJoinGame, playAI, showGame, waitingForOpponent);
        };
        newWS.onerror = (error) => {
            handleError(error);
        };
        newWS.onmessage = (message) => {
            handleMessage(
                message,
                setShowGame,
                setGameState,
                setGame,
                setPlayerColor,
                setGameID,
                setFullGame,
                setForfeit
            );
            setWaitingForOpponent(!JSON.parse(message.data)?.gameStatus?.ready);
        };
        setWs(newWS);
    }

    /**
     * Delete a game when user presses red X.
     * Opens the WS to delete the game.
     */
    function deleteGame() {
        setGameID(null);
        const ws = new WebSocket(MultiplayerConstants.WS_URL);
        ws.onopen = () => {
            ws.send(
                JSON.stringify({
                    action: 'end',
                    gameID: gameID,
                })
            );
            ws.close();
        };
    }

    /* Show the waiting room while waiting for an opponent to join */
    if (waitingForOpponent) {
        return <WaitingRoom setWaitingForOpponent={setWaitingForOpponent} />;
    }

    return (
        <div className="multiplayer-menu" data-testid="multiplayer-menu">
            <h1>Multiplayer</h1>
            {gameID ? (
                <div id="game-row">
                    <button onClick={hostGame} className="chess-button">
                        Game 1
                    </button>
                    <MdCancel id="icon-cancel" size={50} onClick={deleteGame} />
                </div>
            ) : (
                <button onClick={hostGame} className="chess-button">
                    Host Game
                </button>
            )}
            <button
                onClick={() => setShowMultiplayerMenu(false)}
                className="chess-button">
                Back
            </button>
        </div>
    );
}
