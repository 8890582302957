/**
 * GameCenter.js
 * Handles all of the GameCenter integrations.
 * These are required to load Chess from the cabin correctly.
 * Read the docs: https://gitlab.com/sinclair-digital/cloud-ninja-dojo/poc/sinclairuniversity/-/blob/3e2c19f4bb8d5b4f6440856a5252d61c2e4eeb9b/docs/samples/spec.md
 *
 * @author Braden Zingler
 * Last modified 09/16/2024
 */

/**
 * Listen to messages from the Sinclair Game Center.
 * This is required for the Game Center to communicate with the game.
 * This is called in index.js on page load.
 */
export const initGameCenter = () => {
    window.parent.postMessage(
        {
            action: 'ready',
            receiptToken: '{receipt-token}',
        },
        '*'
    );
};

/**
 * Quit the game.
 * This is called by the quit button in the game menu.
 * This sends a message to the parent window to exit the game,
 * which will return the user to the cabin.
 */
export function quitGame() {
    window.parent.postMessage(
        {
            action: 'exit',
        },
        '*'
    );
}
