/**
 * This function validates the users JWT, if they have one. 
 * Return true if the user is authenticated, false otherwise.
 */
const authState = {authenticated: false};
export async function isAuthenticated() {
    if (authState.authenticated !== null) return authState.authenticated;

    try {
        const response = await fetch("https://lakeside-auth-api.nmp.nonprod-sinclairstoryline.com/auth/nonprod", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
        });
        const data = await response.json();
        authState.authenticated = data.ok;
        return data.ok;
    } catch (error) {
        console.log("Error during authentication check:", error);
        return false;
    }
}

/**
 * Redirects the user to the login page of the cabin. After they have logged in, they will be 
 * redirected back to the redirectURL that you provide.
 * At this point you should again check if the user is authenticated before allowing them to proceed.
 * 
 * The below URL will hopefully not be hardcoded someday, or on the same domain atleast. Could probably do something with iFrames.
 * @param {String} redirectURL the URL to redirect the user back to after they have logged in.
 */
export function redirectToLoginPage(redirectURL) {
    window.location.href = `https://test01-nonprod-lakeside-cabin.nmp.nonprod-sinclairstoryline.com/login#redirect_${redirectURL}`;
}