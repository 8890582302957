/**
 * Cards.js
 * The Cards component creates the cards on the table next to the board.
 *
 * @author Braden Zingler
 * Last modified 09/17/24
 */
import { useMountTransition } from '../../../utils/utils';
import cards from './cards.webp';
import './index.css';

export default function Cards() {
    const showCards = window.innerWidth > 1200;
    const hasTransitionedIn = useMountTransition(showCards, 1000);

    if (showCards) {
        return (
            <div className={`cards ${hasTransitionedIn && 'in'}`} data-testid="cards">
                <img src={cards} alt="Cards" className="cards-image" />
            </div>
        );
    }
}
