import { useGame } from '../../game/GameContext';
import { useEffect, useState } from 'react';
import './index.css';
import LoadingScreen from '../../interfaces/LoadingScreen';
import { WaitingRoomConstants } from '../../../utils/constants';
import { FaCheck, FaCopy } from 'react-icons/fa';
const QRCode = require('qrcode');

/**
 * Creates the waiting room component. This is where the player waits for their opponent to join.
 * @param {Object} props the properties of the component.
 * @returns the JSX for the waiting room component.
 */
export default function WaitingRoom({ setWaitingForOpponent }) {
    const { gameID, ws } = useGame();
    const [copied, setCopied] = useState(false);

    /**
     * Creates a QR code for the game URL.
     * This is called in a useEffect hook when the game ID is set,
     * because the game ID is needed to create the QR code.
     */
    function createQRCode() {
        const url = window.location + '#' + gameID;
        const canvas = document.getElementById('qr-code');
        QRCode.toCanvas(
            canvas,
            url,
            WaitingRoomConstants.QR_CODE_CONFIG,
            function (error) {
                if (error) {
                    console.error(error);
                }
            }
        );
    }

    // Create the QR code when the game ID is set.
    useEffect(() => {
        if (gameID) {
            createQRCode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameID]);

    // Reset the copied state after 2 seconds.
    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, WaitingRoomConstants.COPY_DELAY);
        }
    }, [copied]);

    /**
     * Copies the game ID to the clipboard.
     * Changes the copy button to a "copied" state.
     */
    function copyText() {
        const gameURL = document.getElementById('game-id').innerText;
        navigator.clipboard.writeText(gameURL);
        setCopied(true);
    }

    /**
     * Handles clicking on the cancel button in the waiting room.
     */
    function handleClickBack() {
        if (ws) {
            // Delete game and close the WS.
            ws.send({
                action: 'end',
                gameID: gameID,
            });
            ws.close();
        }
        setWaitingForOpponent(false);
    }

    /* 
    When the game ID is null, show the loading screen. 
    Once the backend creates the game, the game ID will be set.
    */
    if (gameID === null) {
        return <LoadingScreen isLoading={true} message={'Creating game...'} />;
    }

    return (
        <div className="waiting-room" data-testid="waiting-room">
            <h1>Waiting for opponent</h1>
            <div className="dot-elastic"></div>
            <h2>Share the URL!</h2>

            {/* URL text and copy button, copies the url to the clipboard. */}
            <div id="id-wrapper">
                <p id="game-id">{window.location + '#' + gameID}</p>
                {copied ? (
                    <FaCheck onClick={copyText} className="icon" />
                ) : (
                    <FaCopy onClick={copyText} className="icon" />
                )}
            </div>

            {/* QR code for the game URL. */}
            <canvas id="qr-code"></canvas>

            {/* Cancel button, returns to the multiplayer menu. */}
            <button id="cancel-button" onClick={handleClickBack}>
                Cancel
            </button>
        </div>
    );
}
