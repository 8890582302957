/**
 * GameContext
 * Context to manage the game state.
 * This is wrapped around the entire app to manage the game state.
 *
 * @component GameContext
 * @returns {JSX.Element} The context provider for the game state.
 * @author Braden Zingler
 * Last modified 09/20/2024
 */
import React, { createContext, useContext, useState } from 'react';

const GameContext = createContext();
export const useGame = () => useContext(GameContext);

export const GameProvider = ({ children }) => {
    const [playAI, setPlayAI] = useState(false);
    const [gameID, setGameID] = useState(null);
    const [showGame, setShowGame] = useState(false);
    const [showMultiplayerMenu, setShowMultiplayerMenu] = useState(false);
    const [ws, setWs] = useState(null);
    const [gameState, setGameState] = useState(null);
    const [joinGame, setJoinGame] = useState(false);
    const [game, setGame] = useState(null);
    const [playerColor, setPlayerColor] = useState(null);
    const [fullGame, setFullGame] = useState(false);
    const [forfeit, setForfeit] = useState({ forfeit: false, playerID: null });

    return (
        <GameContext.Provider
            value={{
                playAI,
                setPlayAI, // Whether the player is playing against the AI.
                gameID,
                setGameID, // The game ID for the multiplayer game.
                showGame,
                setShowGame, // Flag to show/hide the game.
                showMultiplayerMenu,
                setShowMultiplayerMenu, // Flag to show/hide the multiplayer menu.
                ws,
                setWs, // The WebSocket connection.
                gameState,
                setGameState,
                joinGame,
                setJoinGame,
                game,
                setGame,
                playerColor,
                setPlayerColor,
                fullGame,
                setFullGame,
                forfeit,
                setForfeit,
            }}>
            {children}
        </GameContext.Provider>
    );
};
