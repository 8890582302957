/**
 * multiplayer/handlers.js
 *
 * This file contains the logic for handling WebSocket connections for multiplayer games.
 * It contains functions for handling messages, errors, and game updates.
 * It also contains functions for sending moves to the server.
 *
 * Last modified: 10/07/2024
 */
import { Chess } from 'chess.js';
import { getUserId } from '../utils';
import { MultiplayerConstants } from '../constants';

/**
 * Handles the logic for when the WS connection tells the player that the game is ready.
 * This function is called when the opponent has joined the game, and is sent to both opponents.
 * @param {React.Dispatch<React.SetStateAction<boolean>} setShowGame used to set the show game state.
 * @param {React.Dispatch<React.SetStateAction<string>} setGameState used to set the game state.
 * @param {React.Dispatch<React.SetStateAction<Chess>} setGame used to set the game reference.
 * @param {React.Dispatch<React.SetStateAction<string>} setPlayerColor used to set the player's color.
 * @param {Object} messageData the data from the WS message.
 */
export function handleReady(
    setShowGame,
    setGameState,
    setGame,
    setPlayerColor,
    messageData
) {
    if (
        localStorage.getItem('userID') === messageData.game.Player1.M.PlayerID.S
    ) {
        setPlayerColor('w');
    } else {
        setPlayerColor('b');
    }
    setShowGame(true);
    setGameState(messageData.game.GameState.S);
    setGame(new Chess(messageData.game.GameState.S));
}

/**
 * Sends a move to the server.
 * @param {WebSocket} ws the WebSocket connection to the server.
 * @param {String} gameID the game ID to send the move to.
 * @param {String} updatedGameState the updated game state to send.
 */
export function sendMultiplayerGameUpdate(ws, gameID, updatedGameState) {
    ws.send(
        JSON.stringify({
            action: 'move',
            gameID: gameID,
            gameState: updatedGameState,
        })
    );
}

/**
 * Handles an update to the game state.
 * @param {React.Dispatch<React.SetStateAction<string>} setGameState used to update game state.
 * @param {React.Dispatch<React.SetStateAction<Chess>} setGame used to update game reference.
 * @param {Object} messageData the message data from the WS.
 */
export function handleUpdate(setGameState, setGame, messageData) {
    setGameState(messageData.game.GameState.S);
    setGame(new Chess(messageData.game.GameState.S));
}

/**
 * Handles the logic for when the WS connection closes.
 * Re-connects to the server if the connection is lost.
 * @param {WebSocket} ws the WebSocket connection to close.
 * @param {React.Dispatch<React.SetStateAction<boolean>} setIsConnection set the connection state to false.
 * @param {React.Dispatch<React.SetStateAction<boolean>} setWs set the ws state to null.
 */
export function handleClose(
    setWs,
    setJoinGame,
    playAI,
    showGame,
    waitingForOpponent
) {
    console.log('WS disconnected');
    if ((showGame || waitingForOpponent) && !playAI) {
        console.log('Reconnecting...');
        setTimeout(() => {
            setJoinGame(true);
            setWs(null);
        }, MultiplayerConstants.RETRY_DELAY);
    }
}

/**
 * Handles a message from the WebSocket connection.
 *
 * This function is called whenever a message is recieved from the server.
 * It will parse the message and handle the game logic.
 * @param {Object} message the message object from the WebSocket connection.
 * @param {React.Dispatch<React.SetStateAction<boolean>} setShowGame used to set the show game state.
 * @param {React.Dispatch<React.SetStateAction<string>} setGameState used to set the game state.
 * @param {React.Dispatch<React.SetStateAction<Chess>} setGame used to set the game reference.
 * @param {React.Dispatch<React.SetStateAction<string>} setPlayerColor used to set the player's color.
 * @param {React.Dispatch<React.SetStateAction<string>} setGameID used to set the game ID.
 */
export function handleMessage(
    message,
    setShowGame,
    setGameState,
    setGame,
    setPlayerColor,
    setGameID,
    setFullGame,
    setForfeit
) {
    const data = JSON.parse(message.data);
    /* Show the game is full message */
    if (data === 'full_game') {
        setFullGame(true);
        return;
    }
    /* Initial message recieved when creating a new game */
    if (data.gameID) {
        console.log('Setting game ID to', data.gameID);
        localStorage.setItem('gameID', data.gameID);
        setGameID(data.gameID);
    }
    /* Game status message, handles game ready and game updates */
    if (data.gameStatus) {
        if (data.gameStatus === 'ready') {
            handleReady(
                setShowGame,
                setGameState,
                setGame,
                setPlayerColor,
                data
            );
        } else if (data.gameStatus === 'update') {
            handleUpdate(setGameState, setGame, data);
        } else if (data.gameStatus === 'forfeit') {
            setForfeit({ forfeit: true, playerID: data.playerID, winner: data.winner });
        }
    }
}

/**
 * Handles an error in the WebSocket connection.
 * TODO: Show the player or try to reconnect.
 * @param {Error} err
 * @param {WebSocket} ws
 */
export function handleError(err, ws) {
    console.error('WS error', err);
    ws.close();
}

/**
 * Handles the logic for when the player is hosting a game.
 * @param {WebSocket} newWS the WebSocket connection to the server.
 * @param {React.Dispatch<React.SetStateAction<boolean>} setWs the state to set the WebSocket connection to.
 */
export function handleHost(newWS, setWs) {
    console.log('WS opened');
    const userID = getUserId();
    setWs(newWS);
    localStorage.setItem('userID', userID); // TODO dont need this
    newWS.send(
        JSON.stringify({
            action: 'new',
            hostID: userID,
        })
    );
}

/**
 * Handles joining a game.
 * @param {String} gameID the game ID to join.
 * @param {WebSocket} ws the WebSocket connection to the server.
 */
export function handleJoin(gameID, ws) {
    console.log('Joining game with ID: ', gameID);
    const userID = getUserId();
    localStorage.setItem('gameID', gameID);
    ws.send(
        JSON.stringify({
            action: 'join',
            gameID: gameID,
            playerID: userID,
        })
    );
}